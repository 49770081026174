import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
// @mui
import {
    Container,
    Button,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemText,
    Divider,
    Card
} from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// IMAGEN
import logo from '../../../assets/images/logos/logo_scorpion.png';

// CONTEXT
import useTicket from '../../../hooks/useTicket';

export default function CompraGanaMicroSitio() {
    const { ticket } = useTicket();

    return (
        <div>
            <Helmet>
                <title> Compra Gana | Scorpion </title>
            </Helmet>

            <Container maxWidth="lg" style={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid container justifyContent="center">
                    <img src={logo} alt="scorpion" height="140" />
                </Grid>

                <Grid container justifyContent="start" style={{ marginTop: '30px' }}>
                    <Grid item>
                        <Button
                            variant='contained'
                            component={Link}
                            to={'/micro-sitio/mas-promociones'}
                            color='success'
                            startIcon={<WestIcon />}
                        >
                            Regresar
                        </Button>
                    </Grid>
                </Grid>

                <Typography variant='h3' align='center' mt={2} mb={3}>Compra y Gana</Typography>

                {Object.keys(ticket).length !== 0 && (
                    <>
                        <List sx={{ width: '100%', bgcolor: 'background.paper', backgroundColor: '#002d88', borderRadius: '25px' }}>
                            <Grid display="flex" justifyContent="center">
                                <Grid>
                                    <ListItem>
                                        <ListItemText 
                                            primary={<Typography variant='h3' style={{ fontWeight: 'bold', textAlign: 'center', color: 'white' }}>{ticket.compraGana.productosAsignados}</Typography>}
                                            secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center', color: 'white' }}>Productos Asignados</Typography>}/>
                                    </ListItem>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem style={{ background: 'white', borderRightWidth: '3px' }} />
                                <Grid>
                                    <ListItem>
                                        <ListItemText 
                                            primary={<Typography variant='h3' style={{ fontWeight: 'bold', textAlign: 'center', color: 'white' }}>{ticket.compraGana.productosComprados}</Typography>}
                                            secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center', color: 'white' }}>Productos Comprados</Typography>} />
                                    </ListItem>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem style={{ background: 'white', borderRightWidth: '3px' }} />
                                <Grid>
                                    <ListItem>
                                        <ListItemText 
                                            primary={<Typography variant='h3' style={{ fontWeight: 'bold', textAlign: 'center', color: 'white' }}>{`$ ${ticket.compraGana.puntosGanados}`}</Typography>}
                                            secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center', color: 'white' }}>Pesos Ganados</Typography>} />
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </List>
                        <br />
                        {
                            ticket.compraGana.articulos.map((articulo, index) => (
                                <div key={index}>
                                    
                                    <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4, mt: 3 }}>
                                        <Typography variant='h6' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {articulo.nombre}
                                        </Typography>

                                        <Grid display="flex" justifyContent="space-between">
                                            <Grid>
                                                <ListItem>
                                                    {/* https://definicion.de/wp-content/uploads/2009/06/producto.png */}
                                                    <img
                                                        src={articulo.urlImagen}
                                                        alt=""
                                                        width="100" height="100"
                                                    />
                                                </ListItem>
                                            </Grid>
                                            <Grid>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary={<Typography variant='h3' style={{ fontWeight: 'bold', textAlign: 'center' }}>{`$ ${articulo.puntos}`}</Typography>}
                                                        secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>Pesos</Typography>} />
                                                </ListItem>
                                            </Grid>
                                            <Grid>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary={<Typography variant='h3' style={{ fontWeight: 'bold', textAlign: 'center' }}>{articulo.estatusComprado ? <CheckCircleIcon style={{ color: 'green', fontSize: '60px' }} /> : <CancelIcon style={{ color: 'red', fontSize: '60px' }}/>}</Typography>}
                                                        secondary={<Typography variant='subtitle1' style={{ fontWeight: 'bold', textAlign: 'center' }}>{articulo.estatusComprado ? 'Comprado' : 'No Comprado'}</Typography>} />
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </div>
                            ))
                        }
                    </>
                )}

                {
                    Object.keys(ticket.compraGana.articulos).length === 0 && (
                        <div>
                            <Card sx={{ p: 3, boxShadow: 18, borderRadius: 4, mb: 3 }}>
                                <Grid display="flex" justifyContent="center">
                                    <Typography variant='h3' style={{ fontWeight: 'bold', textAlign: 'center' }}>No tienes promociones de Compra y Gana</Typography>
                                </Grid>
                            </Card>
                        </div>
                    )
                }
            </Container>
        </div>
    );
}
