import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
// @mui
import {
    Container,
    Typography,
    Grid,
    Button
} from '@mui/material';

// ICONOS
import WestIcon from '@mui/icons-material/West';
import CompraGanaIcon from '../../components/icons/CompraGanaIcon';
import PesosLealtadIcon from '../../components/icons/PesosLealtadIcon';

// IMAGEN
import logo from '../../../assets/images/logos/logo_scorpion.png';

import useTicket from '../../../hooks/useTicket';


export default function MasPromociones() {
    const { url } = useTicket();

    return (
        <div>
            <Helmet>
                <title> Mas Promociones | Scorpion </title>
            </Helmet>

            <Container maxWidth="lg" style={{ marginTop: '30px' }}>
                <Grid container justifyContent="center">
                    <img src={logo} alt="scorpion" height="140" />
                </Grid>

                <Grid container justifyContent="start" style={{ marginTop: '30px' }}>
                    <Grid item>
                        <Button
                            variant='contained'
                            component={Link}
                            to={url}
                            color='success'
                            startIcon={<WestIcon />}
                        >
                            Regresar
                        </Button>
                    </Grid>
                </Grid>

                <Typography variant='h3' align='center' mt={2}>MAS PROMOCIONES</Typography>

                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid container justifyContent="space-around" style={{ marginTop: '30px', marginBottom: '20px' }}>
                        <Grid item>
                            <Typography variant='h6' textAlign="center" mb={1}>Pesos Lealtad</Typography>
                            <Button
                                variant='contained'
                                component={Link}
                                to={"/micro-sitio/pesos-lealtad"}
                                style={{ height: '150px', width: '150px'}}
                            >
                                {<PesosLealtadIcon />}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography variant='h6' textAlign="center" mb={1}>Compra y Gana</Typography>
                            <Button
                                variant='contained'
                                component={Link}
                                to={"/micro-sitio/compra-gana"}
                                style={{ height: '150px', width: '150px'}}
                            >
                                {<CompraGanaIcon />}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
